@font-face {
  font-family: 'Poppins';
  font-style: normal;
  src: local('Poppins'), local('sans-serif');
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  src: local('Raleway'), local('sans-serif');
  font-display: swap;
}

/* global */

html {
  --lh: 18px;

  overflow-y: auto;
}

ul > li {
  list-style: none;
}

button {
  outline: 0 !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  position: relative;
  margin: 0;
  max-width: 100vw;
  height: 100%;
  background: #f9f9fc;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0;
}

.cursor-pointer { cursor: pointer; }

.row {
  display: flex;
  justify-content: space-around;
}

/* flex */

.flex { display: flex; }
.flex-1 { flex: 1; }
.inline-flex { display: inline-flex; }
.wrap { flex-wrap: wrap; }
.d-row { flex-direction: row; }
.d-column { flex-direction: column; }
.space-between { justify-content: space-between; }
.space-evenly { justify-content: space-evenly; }

/* margins */

.m-auto { margin: auto; }
.mr-auto { margin-right: auto; }

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mt-auto { margin-top: auto; }
.mt-2 { margin-top: 2px; }
.mt-3 { margin-top: 3px; }
.mt-5 { margin-top: 5px; }
.mt-6 { margin-top: 6px; }
.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-25 { margin-top: 25px; }
.mt-30 { margin-top: 30px; }
.mt-35 { margin-top: 35px; }
.mt-40 { margin-top: 40px; }
.mt-48 { margin-top: 48px; }
.mt-60 { margin-top: 60px; }
.mt-70 { margin-top: 70px; }
.mt-80 { margin-top: 80px; }

.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-20 { margin-right: 20px; }
.mr-40 { margin-right: 40px; }
.mr-60 { margin-right: 60px; }
.mr-65 { margin-right: 65px; }

.mb-2 { margin-top: 2px; }
.mb-6 { margin-top: 6px; }
.mb-10 { margin-bottom: 10px; }
.mb-12 { margin-bottom: 12px; }
.mb-15 { margin-bottom: 15px; }
.mb-16 { margin-bottom: 16px; }
.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-45 { margin-bottom: 45px; }
.mb-60 { margin-bottom: 60px; }
.mb-100 { margin-bottom: 100px; }

.ml-auto { margin-left: auto; }
.ml-9 { margin-left: 9px; }
.ml-10 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }
.ml-25 { margin-left: 25px; }
.ml-30 { margin-left: 30px; }
.ml-40 { margin-left: 40px; }
.pl-40 { padding-left: 40px; }

.pt-2 { padding-top: 2px; }
.pt-25 { padding-top: 25px; }
.pt-50 { padding-top: 50px; }

.pb-2 { padding-bottom: 2px; }
.pb-20 { padding-bottom: 20px; }

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 66%;
}

.ox-auto { overflow-x: auto; }

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* positions */

.pos-rel { position: relative; }

/* dimensions */

.max-w-50 { max-width: 50%; }
.max-w-100 { max-width: 100%; }
.max-w-410 { max-width: 410px; }
.max-w-450 { max-width: 450px; }
.max-w-vw { max-width: calc(var(--vw, 1vw) * 22); }
.max-w-vw2 { max-width: calc(var(--vw, 1vw) * 44); }
.max-w-full { max-width: calc(100%); }
.max-w-50px { max-width: calc(50%); }

.clearfix { overflow: auto; }
.w-490 { width: 490px; }
.w-470 { width: 470px; }
.w-450 { width: 450px; }
.w-400 { width: 400px; }
.w-100 { width: 100%; }
.w-50 { width: 50%; }
.h-100 { height: 100%; }
.h-34 { height: 34px; }

/* aligns */

.v-center { align-items: center; }
.v-top { align-items: flex-start; }
.v-bottom { align-items: flex-end; }
.h-center { justify-content: center; }
.h-start { justify-content: start; }
.t-center { text-align: center; }

/* forms */


/* forms */

.tooltip { position: relative; }

.tooltip .tooltip-text {
  min-width: 150px;
  bottom: 100%;
  left: 50%;
  visibility: hidden;
  background-color: #e4af27;
  color: #fff;
  text-align: center;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

/*
.tooltip-text2 {
  width: 250px !important;
  background-color: #e4af27 !important;
  color: #fff !important;
  text-align: center !important;
  padding: 5px 10px 5px 10px !important;
  border-radius: 5px !important;
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 10px !important;
  line-height: 15px !important;
}
*/

/* phone input */

.react-tel-input .input-phone.form-control {
  width: 100%;
  height: 34px;
  background: #fff;
  color: #505259;
  padding-right: 15px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  border: none;
  border: 1px solid #efeff2;
  border-radius: 5px;
}

.react-tel-input .input-phone-disabled.form-control {
  width: 100%;
  height: 34px;
  background: #f9f9fc;
  color: #505259;
  padding-right: 15px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  border: none;
  border: 1px solid #efeff2;
  border-radius: 5px;
}

.react-tel-input.error .input-phone.form-control {
  border-color: #bb002f;
}

.react-tel-input .input-phone-btn.flag-dropdown,
.react-tel-input .input-phone-btn.flag-dropdown.open {
  border-color: transparent;
  top: 1px;
  left: 1px;
  bottom: 1px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

input[type="checkbox"]:checked {
  background: #9c3;
  border: 0.5px solid #9c3;
}

*::placeholder {
  color: #505259;
  opacity: 0.3;
}

*::-webkit-input-placeholder {
  color: #505259;
  opacity: 0.3;
}

*::-moz-placeholder {
  color: #505259;
  opacity: 0.3;
}

*::-ms-input-placeholder {
  color: #505259;
  opacity: 0.3;
}

.nowrap { white-space: nowrap; }
.pre { white-space: pre-line; }
.pointer { cursor: pointer; }

/* charts */

#rev-chart {
  background: #fff;
  width: 100%;
}

/* modal */

.ReactModal__Body--open {
  overflow: hidden;
}

.recharts-responsive-container {
  margin-left: -40px;
  min-width: calc(100% + 40px);
}

.recharts-legend-item .recharts-surface {
  display: none;
}

.pdf-viewer {
  height: auto;
  display: flex;
  flex-direction: column;
}

.pdf-viewer div {
  height: auto !important;
}

.pdf-viewer canvas {
  width: 100%;
  max-width: 700px;
}

/* media */

.d-none {
  display: none !important;
}

@media (max-width: 400px) {
  .d-xss-none {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .d-xs-none {
    display: none !important;
  }

  .d-xs-flex {
    display: flex !important;
  }

  .d-xs-block {
    display: block !important;
  }
}

@media (max-width: 991px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-flex {
    display: flex !important;
  }
}

@media (max-width: 1199px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-flex {
    display: flex !important;
  }
}

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-flex {
    display: flex !important;
  }
}

/* ul/ li */

.dashed {
  list-style-type: none;
}

.dashed > li {
  display: flex;
}

.dashed > li::before {
  content: "-";
  text-indent: -20px;
}

/* tutorial */

.right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #0bf;
}

.left {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #0bf;
}

.tutor-edit {
  position: absolute;
  right: 65px;
  top: 20px;
}

.tutor-main {
  position: absolute;
  top: -40px;
  right: 120px;
}

.tutor-md {
  position: absolute;
  top: -40px;
  right: 120px;
}

.tutor-kyc {
  margin-top: -5px;
}

.illustration {
  display: flex;
  width: 100%;
  max-height: 100%;
  justify-content: center;

  @media (max-width: 991px) {
    max-height: 100%;
    width: auto;
    max-width: 100%;
    height: auto;
  }
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3000;
}

.tos {
  font-family: Poppins, sans-serif;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #a0a5b3;
}

.tos-link {
  font-family: Poppins, sans-serif;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #0bf;
}

.truncate-overflow {
  --max-lines: 3;

  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem;
}

.truncate-overflow::before {
  position: absolute;

  /* content: "..."; */
  inset-block-end: 0;
  inset-inline-end: 0;
}

.truncate-overflow::after {
  content: "";
  position: absolute;
  inset-inline-end: 0;
  width: 1rem;
  height: 1rem;
  background: white;
}

.more {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #0bf;
  cursor: pointer;
  white-space: nowrap;
}

.line-10 {
  --max-lines: 10;

  font-family: Poppins, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #505259;
  margin-top: 10px;
  max-width: 100%;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  visibility: hidden;

  @media (max-width: 767px) {
    max-width: 100%;
  }
}

/* progress */

progress {
  -webkit-appearance: none;
}

::-webkit-progress-bar {
  background-color: #efeff2;
  border-radius: 2px;
  height: 16px;
}

::-webkit-progress-value {
  background-color: #00d6bd;
}

::-moz-progress-bar {
  background-color: #efeff2;
  border-radius: 2px;
  height: 16px;
}

::-moz-progress-value {
  background-color: #00d6bd;
}

/* ol counter */

ol {
  list-style: none;
  counter-reset: cnt;
}

ol li {
  counter-increment: cnt;
  display: flex;
}

ol li::before {
  content: counter(cnt) ". ";
  margin-right: 5px;
  font-family: Poppins;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #505259;
}

::-webkit-scrollbar {
  width: 6px;
  background: #e8e8ea;
}

::-webkit-scrollbar-thumb {
  background: #c1c2c6;
  border-radius: 10px;
}

.invisible {
  display: none;
  position: absolute;
  top: -9999px;
  border: 0;
  margin: 0;
  padding: 0;
  width: 1px;
  height: 1px;
}
